import NavBar from '../../Components/NavBar/NavBar';
import Bio from '../Bio/Bio';

const Home = () => {
    return (
        <header>
          <NavBar />
          <Bio></Bio>
        </header>
      );
}

export default Home;